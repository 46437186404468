import {request, request_check} from "../plugins/axios";

// 商城首页
export function decorationShow (params) {
    return request_check({
        url: '/shop/decorationShow',
        method: 'get',
        params: params
    })
}

// 商品详情
export function h5ProductShow (params) {
    return request_check({
        url: '/shop/h5ProductShow',
        method: 'get',
        params: params
    })
}

// 分类列表
export function h5CateIndex (params) {
    return request_check({
        url: '/shop/h5CateIndex',
        method: 'get',
        params: params
    })
}

// 商品列表
export function h5ProductIndex (params) {
    return request_check({
        url: '/shop/h5ProductIndex',
        method: 'get',
        params: params
    })
}

// 订单列表
export function myOrderIndex (params) {
    return request_check({
        url: '/shop/myOrderIndex',
        method: 'get',
        params: params
    })
}

// 提交订单
export function launchOrder (params) {
    return request_check({
        url: '/shop/launchOrder',
        method: 'post',
        data: params
    })
}

// 订单详情
export function orderShow (params) {
    return request_check({
        url: '/shop/orderShow',
        method: 'get',
        params: params
    })
}

// 支付订单
export function placeOrder (params) {
    return request_check({
        url: '/shop/placeOrder',
        method: 'get',
        params
    })
}
